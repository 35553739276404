import * as React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function ProgressInternal(props) {
   return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
         <Box sx={{ width: '100%', mr: 1 }}>
            <LinearProgress variant="determinate" {...props} />
         </Box>
         <Box sx={{ minWidth: 35 }}>
            <Typography variant="body2" color="text.secondary">{`${Math.round(
               props.value,
            )}%`}</Typography>
         </Box>
      </Box>
   );
}

Progress.propTypes = {
   /**
    * The value of the progress indicator for the determinate and buffer variants.
    * Value between 0 and 100.
    */
   //value: PropTypes.number.isRequired,
};

export default function Progress( {prop} ) {

   //const [progress, setProgress] = React.useState(prop.cur);

   React.useEffect(() => {
      // const timer = setInterval(() => {
      //    setProgress((prevProgress) => (prevProgress >= 100 ? 10 : prevProgress + 10));
      // }, 800);
      // return () => {
      //    clearInterval(timer);
      // };
   }, []);

   return (
      <Box sx={{
         width: '100%',
         backgroundColor: '#efefef'
         }
      }>
         <Typography variant="body2" component="span" sx={{color: 'grey'}}>Шаг {prop.cur+1} из 20</Typography>
         <ProgressInternal value={(prop.cur + 1) * 5} />
      </Box>
   );
}