import * as React from 'react';
import {
   Box,
   Button,
   Paper,
   Radio,
   RadioGroup,
   TextField,
   Typography
} from "@mui/material";
import logo from '../img/logo.png';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Progress from "../components/Progress";



function Home() {

   const [answers, setAnswers] = React.useState([])
   const [progress, setProgress] = React.useState(0)
   const [questions] = React.useState([
      "Процессы выполнения услуг у нас подробно задокументированы. За каждым из них закреплён ответственный.",
      "Мы пользуемся отработанными шаблонами для разных типов проектов, задач и клиентов.",
      "У нас работает проверенная система измерения рабочей нагрузки, управления ресурсами и прогнозирования персонала.",
      "Каждый член команды чётко понимает как пользоваться рабочими инструментами.",
      "Всё работает как часы, ничто не остаётся незамеченными.",
      "Все сотрудники знают, как и где найти нужную информацию.",
      "Мы знаем все ключевые показатели по контролю качества, эффективности и прибыльности команды. Они постоянно используются для принятия управленческих решения по мере роста компании.",
      "У нас есть целевые показатели использования для каждого сотрудника и/или отдела, которые по мере необходимости корректируются.",
      "У нас есть прозрачный типовой процесс добавления и планирования задач на платформе управления проектами.",
      "У нас есть описанные правила участия для всех сотрудников, которые однозначно определяют, как использовать инструменты управления проектами.",
      "Каждое утро мы точно знаем список запланированных на сегодня дел.",
      "В нашей команде здоровая атмосфера общения",
      "Наши внутренние встречи целенаправленны, содержательны и продуктивны.",
      "Мы устанавливаем реалистичные и выполнимые ожидания клиентами.",
      "Процесс адаптации клиентов проходит гладко (и клиенты нам об этом часто говорят).",
      "У нас есть отлаженный типовой способ фиксации задач после собраний в платформе управления проектами.",
      "У нас есть система измерения удовлетворенности клиентов. Она помогает нам выявлять проблемы до их появления.",
      "У нас есть отчеты, показывающие прибыльность по клиентам, службам, отделам и отдельным сотрудникам. Мы их постоянно просматриваем (ежемесячно или ежеквартально).",
      "Мы знаем показатели удержания клиентов, причины оттока, LTV и можем легко сегментировать эти данные.",
   ]);
   const [radioValue, setRadioValue] = React.useState("")
   const [email, setEmail] = React.useState("")
   const [hideGettingEmailBlock, setHideGettingEmailBlock] = React.useState(false)
   const [resultTexts] = React.useState({text1 : "", text2 : ""})



   const handleAnswer = (event) => {

      let answerScore = 0
      switch (event.target.id) {
         case "answer1" : answerScore = 1; break;
         case "answer2" : answerScore = 2; break;
         case "answer3" : answerScore = 3; break;
         case "answer4" : answerScore = 4; break;
         case "answer5" : answerScore = 5; break;
         default: answerScore = 0

      }

      answers.push(answerScore)
      setRadioValue(event.target.value)


      setTimeout( () => {

         console.log('Timer\'s been triggered')

         setAnswers(answers)
         setProgress(progress + 1)
         setRadioValue("")

      }, 600)



   }

   const storeDataToClickUp = async () => {

      const dataForClickUp = {
         email: email,
         answers: JSON.stringify(answers)
      }

      const url = 'https://dev.blueocean24.ru/temp/benchmark_store_in_clickup.php'
      const response = await fetch(url, {
         method: 'POST',         // *GET, POST, PUT, DELETE, etc.
         mode: 'no-cors',        // no-cors, *cors, same-origin
         cache: 'no-cache',      // *default, no-cache, reload, force-cache, only-if-cached
         credentials: 'include', // include, *same-origin, omit
         headers: {
            'Content-Type': 'application/json'
            // 'Content-Type': 'application/x-www-form-urlencoded',
         },
         redirect: 'follow',     // manual, *follow, error
         referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
         body: JSON.stringify(dataForClickUp) // body data type must match "Content-Type" header
      });

      // console.log("Response result:")
      // console.log(response)



   }

   const handleEmailChange = (event) => {
      setEmail(event.target.value);
   };


   function getBenchmarkResults() {


      storeDataToClickUp()


      setHideGettingEmailBlock(true)

      // посчитаем результат и заполним текстовки следующего блока

      let benchmarkResult = 0
      answers.forEach( (item) => {
         benchmarkResult += item
      })

      if(benchmarkResult >= 76) {
         resultTexts.text1 = `Поздравляем, вы вошли в лучшие 20% компаний с общим результатом ${benchmarkResult}/95!`
         resultTexts.text2 = `И даже не смотря на то, что всегда есть к чему стремиться, – вы должны гордиться тем, что создали.`
      }
      else {
         resultTexts.text1 = `Ваш результат теста: ${benchmarkResult}/95`
         resultTexts.text2 = `Хотя ваши сегодняшние результаты нельзя назвать идеальным стандартом работы агентства, смотрите на этой как на возможность для улучшения. Мы работали со многими агентствами, которые существенно реструктурировали свою работу всего за несколько месяцев, и вы можете сделать то же самое!`
      }
   }







   return (

      <Box sx={{
         mx: 'auto',
//         px: 0,
         mt: 2,
         width: 840,

         display: 'flex',
         flexDirection: 'column',
      }}
      >


         <Paper
            sx={{


               py: 3, // padding top & bottom
               px: 7, // padding left & right

               display: (progress !== 19) ? 'flex' : 'none',
               flexDirection: 'column',
               gap: 4,

               borderRadius: 1,
               boxShadow: 3,

               backgroundColor: '#efefef',
            }}

         >
            <Box
               sx={{
                  mx: 'auto',
                  mt: 2
               }}
            >
               <img src={logo} width={150} alt='A-Method'/>
            </Box>

            <Box
               sx={{
                  mx: 'auto'
               }}
            >
               <Typography variant="h5" component="h1" align='center'>
                  Насколько прокачен менеджмент в вашем агентстве?
               </Typography>
            </Box>

            <Box
               sx={{
                  mx: 'auto',
                  my: 1,
                  width: 650,
                  backgroundColor: '#ffffff'
               }}
            >
               <Progress prop={ {cur: progress} } />

            </Box>

            <Box hidden = {(progress === 0) ? false : true}>
               <Typography variant="h5" component="h1" align='center'>
                  20 вопросов для оценки уровня менеджмента агентства
               </Typography>
               <Box sx={{height: "20px"}} />
               <Typography variant="body1" component="span" sx={{color: 'grey' }}>
                  <ul>
                     <li>Эти вопросы (и примеры, приведенные в результатах анализа) повысят продуктивность и прибыльность в компании.</li>
                     <li>Просто отвечайте на каждый вопрос по шклае от «Совершенно не согласен» до «Полностью согласен».</li>
                     <li>После прохождения опроса, у вас будет оценка и данные эталонного теста. Приступим!</li>
                  </ul>
               </Typography>

            </Box>

            <Box sx={{
               backgroundColor: '#ffffff',
               borderRadius: 2,
               boxShadow: 3,
               p: 2,
               mb: 2
            }}>

               <Typography variant="h5" component="h1" align='center'>

                  {questions[progress]}

               </Typography>

               <Box sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: 4,
                  m: 1,
                  py: 2
               }}
               >

                  <Box sx={{
                     display: "flex",
                     flexDirection: 'column'
                  }}>
                     <Box sx={{height: '40px', textAlign: 'center', my: 'auto'}} align='center' >
                        <Typography variant="body2" component="span" sx={{ fontWeight: 'bold' }}>Крайне<br/> не согласен</Typography>
                     </Box>
                     <RadioGroup
                        value={radioValue}
                     >
                        <Radio id='answer1' value="answer1" onClick={handleAnswer}/>
                     </RadioGroup>
                  </Box>
                  <Box  sx={{display: "flex", flexDirection: 'column'}}>
                     <Box sx={{height: '40px'}} align='center'>
                        <Typography variant="body2" component="span" sx={{ fontWeight: 'bold', m: '0', lineHeight: '40px' }}>Не согласен</Typography>
                     </Box>
                     <RadioGroup
                        value={radioValue}
                     >
                        <Radio id='answer2' value="answer2" onClick={handleAnswer} />
                     </RadioGroup>
                  </Box>
                  <Box sx={{display: "flex", flexDirection: 'column'}}>
                     <Box sx={{height: '40px'}} align='center'>
                        <Typography variant="body2" component="span" sx={{ fontWeight: 'bold', m: '0', lineHeight: '40px' }}>Нейтрален</Typography>
                     </Box>
                     <RadioGroup
                        value={radioValue}
                     >
                        <Radio  id='answer3' value="answer3" onClick={handleAnswer}/>
                     </RadioGroup>
                  </Box>
                  <Box sx={{display: "flex", flexDirection: 'column'}}>
                     <Box sx={{height: '40px'}} align='center'>
                        <Typography variant="body2" component="span" sx={{ fontWeight: 'bold', m: '0', lineHeight: '40px' }}>Согласен</Typography>
                     </Box>
                     <RadioGroup
                        value={radioValue}
                     >
                        <Radio id='answer4' value="answer4" onClick={handleAnswer} />
                     </RadioGroup>
                  </Box>
                  <Box sx={{display: "flex", flexDirection: 'column'}}>
                     <Box sx={{height: '40px'}} align='center'>
                        <Typography variant="body2" component="span" sx={{ fontWeight: 'bold' }}>Полностью<br/>согласен</Typography>
                     </Box>
                     <RadioGroup
                        value={radioValue}
                     >
                        <Radio id='answer5' value="answer5" onClick={handleAnswer} />
                     </RadioGroup>
                  </Box>

               </Box>
            </Box>
         </Paper>



         <Paper
            sx={{
               py: 3, // padding top & bottom
               px: 7, // padding left & right

               display: (progress >= 19) && !hideGettingEmailBlock ? 'flex' : 'none',
               flexDirection: 'column',
               gap: 4,

               borderRadius: 1,
               boxShadow: 3,

               backgroundColor: '#efefef'
            }}

         >
            <Box
               sx={{
                  mx: 'auto',
                  mt: 2
               }}
            >
               <img src={logo} width={150} alt='A-Method'/>
            </Box>

            <Box
               sx={{
                  mx: 'auto'
               }}
            >
               <Typography variant="h5" component="h1" align='center'>
                  Насколько прокачен менеджмент в вашем агентстве?
               </Typography>
            </Box>

            <Box
               sx={{
                  mx: 'auto',
                  my: 1,
                  width: 750,
                  backgroundColor: '#ffffff'
               }}
            >
               <Progress prop={ {cur: progress} } />

            </Box>

            <Box sx={{
               display: 'flex',
               flexDirection: 'column',
               backgroundColor: '#ffffff',
               borderRadius: 2,
               boxShadow: 3,
               p: 4,
               gap: 2,
               mb: 2
            }}>

               <TextField id="email" label="Email" value={email} fullWidth onChange={handleEmailChange} />

               <Typography variant="body2" component="h1" align='left'>
                  На email отправим копию результатов, а также полную статистику контрольных данных из более чем 1500 агентств.
               </Typography>

               <Button variant="contained" sx={{width: '320px', alignSelf: 'center'}} onClick={getBenchmarkResults}>
                  Получить оценку агентства
               </Button>


            </Box>
         </Paper>




         <Paper
            sx={{
               py: 4, // padding top & bottom
               px: 7, // padding left & right

               display: (progress >= 19) && hideGettingEmailBlock ? 'flex' : 'none',
               flexDirection: 'column',
               gap: 4,

               borderRadius: 1,
               boxShadow: 3,

               backgroundColor: '#efefef',

            }}

         >
            <Box
               sx={{
                  mx: 'auto',
                  mt: 2
               }}
            >
               <img src={logo} width={150} alt='A-Method'/>
            </Box>


            <Box
               sx={{
                  display: 'flex',
                  py: 0, // padding top & bottom
                  px: 0, // padding left & right

                  flexDirection: 'column',
                  gap: 4,

               }}
            >
               <Typography variant="h5" component="h5" align='center' sx={{fontWeight: 'bold'}}>
                  {resultTexts.text1}
               </Typography>

               <Typography variant="body1" component="" align='left'>
                  {resultTexts.text2}
               </Typography>

               <Typography variant="h4" component="h4" align='left'>
                  Эталонные данные тестов
               </Typography>

               <Typography variant="body1" component="" align='left'>
                  Если вы указали свой email, мы только что отправили вам полную разбивку контрольных данных, собранных более чем 1500 команд.
                  Если вы не получили этот отчет, отправьте нам письмо на info@ametod.ru, – мы обязательно вышлем копию.
               </Typography>

               <Typography variant="h4" component="h4" align='left'>
                  Переход на следующий уровень
               </Typography>

               <Typography variant="body1" component="" align='left'>
                  Готовы стать настоящим профессионалом в управлении проектами и агентском менеджменте?
                  Давайте запланируем 30-минутный разговор, чтобы расставить приоритеты и вместе составить план действий! :)
               </Typography>


                <Button
                   onClick={() => {window.location.href = 'https://calendly.com/ametod/30min'}}
                   variant="contained"
                   sx={{ alignSelf: 'center'}}
                >
                   Запланировать Zoom-call
                </Button>


            </Box>


         </Paper>










          {/*<Button variant="contained">Hello World</Button>

          <Paper
             sx={{
                width: 300,
                mx: 'auto', // margin left & right
                my: 4, // margin top & botom
                py: 3, // padding top & bottom
                px: 2, // padding left & right
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                borderRadius: 2,
                boxShadow: 3,
             }}
          >
             <div>
                <Typography level="h4" component="h1">
                   Welcome!
                </Typography>
                <Typography level="body2">Sign in to continue.</Typography>
             </div>

             <TextField
                // html input attribute
                name="email"
                type="email"
                placeholder="johndoe@email.com"
                // pass down to FormLabel as children
                label="Email"
             />
             <TextField
                name="password"
                type="password"
                placeholder="password"
                label="Password"
             />

             <Button variant="contained" sx={{ mt: 1 }}>
                Log in
             </Button>

             <Typography
                fontSize="sm"
                sx={{ alignSelf: 'center' }}
             >
             </Typography>

          </Paper>*/}

{/*
         <Box sx={{marginRight: 0}}>
            <Link>test</Link>asd fasf dasdf
         </Box>
*/}

      </Box>

  )
}

export default Home


