import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";

import { ThemeProvider, createTheme } from '@mui/material/styles';
import { GlobalStyles } from "@mui/material";

const theme = createTheme({
   palette: {
      //type: 'light',
      primary: {
         main: '#7a73fe',
         light: '#cbc9f7'
      },
      secondary: {
         main: '#f98d00',
      },
   },
});

function App() {
  return (


     <ThemeProvider theme={theme}>

        <GlobalStyles
           styles={{
              body: { backgroundColor: "#2c387e", margin: 0, padding: 0 }
           }}
        />


         <Router>
           <div className='flex flex-col justify-between '>

             {/*            <Navbar />*/}

             {/*<main className='container mx-auto px-3 pb-12'>*/}
             {/*<main className='container mx-auto max-w-fit'>*/}
             <main className=''>

               {/*<Alert />*/}

               <Routes>
                 <Route path='/' element={<Home />} />
                 {/*
                <Route path='/about' element={<About />} />
                <Route path='/user/:login' element={<User />} />
*/}
                 <Route path='/notfound' element={<NotFound />} />
                 <Route path='*' element={<NotFound />} />
               </Routes>
             </main>

             {/*            <Footer />*/}

           </div>
         </Router>

     </ThemeProvider>

  );
}

export default App;
